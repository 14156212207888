import * as StateMachine from 'javascript-state-machine';

import {Event, EventMap} from '../enums/Event';
import {AnalyticsConfig} from '../types/AnalyticsConfig';
import {AnalyticsStateMachineOptions} from '../types/AnalyticsStateMachineOptions';
import {NoExtraProperties} from '../types/NoExtraProperties';
import {StateMachineCallbacks} from '../types/StateMachineCallbacks';
import {EventDebugging} from '../utils/EventDebugging';
import {logger, padRight} from '../utils/Logger';
import {noOp} from '../utils/Utils';

import {AnalyticsStateMachine} from './AnalyticsStateMachine';

enum State {
  SETUP = 'SETUP',
  STARTUP = 'STARTUP',
  READY = 'READY',
  PLAYING = 'PLAYING',
  REBUFFERING = 'REBUFFERING',
  PAUSE = 'PAUSE',
  QUALITYCHANGE = 'QUALITYCHANGE',
  PLAY_SEEKING = 'PLAY_SEEKING',
  END_PLAY_SEEKING = 'END_PLAY_SEEKING',
  QUALITYCHANGE_PAUSE = 'QUALITYCHANGE_PAUSE',
  QUALITYCHANGE_REBUFFERING = 'QUALITYCHANGE_REBUFFERING',
  END = 'END',
  ERROR = 'ERROR',
  /** This event is called when an ad is played directly after the player startup phase */
  AD_STARTUP = 'AD_STARTUP',
  AD_READY = 'AD_READY',
  AD_PAUSE = 'AD_PAUSE',
  AD_PLAYING = 'AD_PLAYING',
  MUTING_READY = 'MUTING_READY',
  MUTING_PLAY = 'MUTING_PLAY',
  MUTING_PAUSE = 'MUTING_PAUSE',
  CASTING = 'CASTING',
  SOURCE_CHANGING = 'SOURCE_CHANGING',
  INITIAL_SOURCE_CHANGE = 'INITIAL_SOURCE_CHANGE',
  SOURCE_LOADED = 'SOURCE_LOADED',
  SUBTITLE_CHANGING = 'SUBTITLE_CHANGING',
  AUDIOTRACK_CHANGING = 'AUDIOTRACK_CHANGING',
  EXIT_BEFORE_VIDEOSTART = 'EXIT_BEFORE_VIDEOSTART',
  CUSTOMDATACHANGE = 'CUSTOMDATACHANGE',
}

export class Bitmovin8AnalyticsStateMachine extends AnalyticsStateMachine {
  private debuggingStates: EventDebugging[] = [];
  private enabledDebugging = false;

  private seekTimestamp: number;
  private seekedTimestamp: number;
  private seekStarted: boolean;
  private seekEnded: boolean;
  // used for ads, because when AdFinished event is fired and call to state machine is made
  // data form player indicate that video is playing from beginning even if ad is in middle of video
  private lastTrackedVideoEndObject: any;

  constructor(stateMachineCallbacks: StateMachineCallbacks, opts: AnalyticsStateMachineOptions) {
    super(stateMachineCallbacks, opts);
    this.seekTimestamp = 0;
    this.seekedTimestamp = 0;
    this.seekStarted = false;
    this.seekEnded = false;
  }

  getAllStatesBut(states: string[]) {
    return this.getAllStates().filter((i) => states.indexOf(i) < 0);
  }

  getAllStates() {
    return Object.keys(State).map((key) => State[key]);
  }

  sourceChange = (config: AnalyticsConfig, timestamp: number, currentTime?: number) => {
    this.callEvent(Event.MANUAL_SOURCE_CHANGE, {config, currentTime}, timestamp);
  };

  createStateMachine(opts: AnalyticsStateMachineOptions): StateMachine.StateMachine {
    return StateMachine.create({
      initial: State.SETUP,
      error: (eventName, from, to, args, errorCode, errorMessage) => {
        logger.error('Error in statemachine: ' + errorMessage);
      },
      events: [
        // {name: Event.SOURCE_LOADED, from: [State.SETUP, State.ERROR, State.SOURCE_CHANGING], to: State.READY},
        {
          name: Event.SOURCE_LOADED,
          from: [State.SETUP, State.ERROR, State.SOURCE_CHANGING, State.INITIAL_SOURCE_CHANGE],
          to: State.READY,
        },

        {name: Event.PLAY, from: State.READY, to: State.STARTUP},
        {name: Event.PLAYING, from: State.READY, to: State.PLAYING},
        {name: Event.READY, from: State.READY, to: State.READY},
        {name: Event.VIDEO_CHANGE, from: State.READY, to: State.READY},
        {name: Event.AUDIO_CHANGE, from: State.READY, to: State.READY},

        {name: Event.ERROR, from: [State.STARTUP, State.AD_STARTUP], to: State.EXIT_BEFORE_VIDEOSTART},
        {name: Event.UNLOAD, from: [State.STARTUP, State.AD_STARTUP], to: State.EXIT_BEFORE_VIDEOSTART},
        {name: Event.VIDEOSTART_TIMEOUT, from: State.STARTUP, to: State.EXIT_BEFORE_VIDEOSTART},

        {name: Event.PLAY, from: State.STARTUP, to: State.STARTUP},
        {name: Event.PLAYING, from: State.STARTUP, to: State.STARTUP},
        {name: Event.TIMECHANGED, from: State.STARTUP, to: State.PLAYING},
        {name: Event.START_BUFFERING, from: State.STARTUP, to: State.STARTUP},
        {name: Event.END_BUFFERING, from: State.STARTUP, to: State.STARTUP},
        {name: Event.VIDEO_CHANGE, from: State.STARTUP, to: State.STARTUP},
        {name: Event.AUDIO_CHANGE, from: State.STARTUP, to: State.STARTUP},
        {name: Event.READY, from: State.STARTUP, to: State.STARTUP},
        {name: Event.PAUSE, from: State.STARTUP, to: State.READY},

        {name: Event.PLAYING, from: State.PLAYING, to: State.PLAYING},
        {name: Event.TIMECHANGED, from: State.PLAYING, to: State.PLAYING},
        {name: Event.END_BUFFERING, from: State.PLAYING, to: State.PLAYING},
        {name: Event.START_BUFFERING, from: State.PLAYING, to: State.REBUFFERING},
        {name: Event.END_BUFFERING, from: State.REBUFFERING, to: State.PLAYING},
        {name: Event.TIMECHANGED, from: State.REBUFFERING, to: State.REBUFFERING},

        {name: Event.PAUSE, from: State.PLAYING, to: State.PAUSE},
        {name: Event.PAUSE, from: State.REBUFFERING, to: State.PAUSE},
        {name: Event.PLAY, from: State.PAUSE, to: State.PLAYING},

        {name: Event.VIDEO_CHANGE, from: State.PLAYING, to: State.QUALITYCHANGE},
        {name: Event.AUDIO_CHANGE, from: State.PLAYING, to: State.QUALITYCHANGE},
        {name: Event.VIDEO_CHANGE, from: State.QUALITYCHANGE, to: State.QUALITYCHANGE},
        {name: Event.AUDIO_CHANGE, from: State.QUALITYCHANGE, to: State.QUALITYCHANGE},
        {name: 'FINISH_QUALITYCHANGE', from: State.QUALITYCHANGE, to: State.PLAYING},

        {name: Event.VIDEO_CHANGE, from: State.PAUSE, to: State.QUALITYCHANGE_PAUSE},
        {name: Event.AUDIO_CHANGE, from: State.PAUSE, to: State.QUALITYCHANGE_PAUSE},
        {
          name: Event.VIDEO_CHANGE,
          from: State.QUALITYCHANGE_PAUSE,
          to: State.QUALITYCHANGE_PAUSE,
        },
        {
          name: Event.AUDIO_CHANGE,
          from: State.QUALITYCHANGE_PAUSE,
          to: State.QUALITYCHANGE_PAUSE,
        },
        {name: 'FINISH_QUALITYCHANGE_PAUSE', from: State.QUALITYCHANGE_PAUSE, to: State.PAUSE},

        //#region Seeking

        // start seeking
        {name: Event.SEEK, from: State.PLAYING, to: State.PLAY_SEEKING},

        // mark the seeked as finished
        {name: Event.PLAY, from: State.PLAY_SEEKING, to: State.END_PLAY_SEEKING},
        {name: Event.PLAYING, from: State.PLAY_SEEKING, to: State.END_PLAY_SEEKING},
        {name: Event.SEEKED, from: State.PLAY_SEEKING, to: State.END_PLAY_SEEKING},

        // seek while previous seek not completed
        {name: Event.SEEK, from: State.END_PLAY_SEEKING, to: State.PLAY_SEEKING},

        // seek completely finished and cont playing
        {name: Event.PLAYING, from: State.END_PLAY_SEEKING, to: State.PLAYING},
        {name: Event.TIMECHANGED, from: State.END_PLAY_SEEKING, to: State.PLAYING},

        //#region disable transitions
        // disable tranitions in PLAY_SEEKING
        {name: Event.SEEK, from: State.PLAY_SEEKING, to: State.PLAY_SEEKING},
        {name: Event.AUDIO_CHANGE, from: State.PLAY_SEEKING, to: State.PLAY_SEEKING},
        {name: Event.VIDEO_CHANGE, from: State.PLAY_SEEKING, to: State.PLAY_SEEKING},
        {name: Event.START_BUFFERING, from: State.PLAY_SEEKING, to: State.PLAY_SEEKING},
        {name: Event.END_BUFFERING, from: State.PLAY_SEEKING, to: State.PLAY_SEEKING},
        // disable transitions in END_PLAY_SEEKING
        {name: Event.START_BUFFERING, from: State.END_PLAY_SEEKING, to: State.END_PLAY_SEEKING},
        {name: Event.END_BUFFERING, from: State.END_PLAY_SEEKING, to: State.END_PLAY_SEEKING},
        {name: Event.SEEKED, from: State.END_PLAY_SEEKING, to: State.END_PLAY_SEEKING},
        {name: Event.PLAY, from: State.END_PLAY_SEEKING, to: State.END_PLAY_SEEKING},
        // disable Seek events during PAUSE
        {name: Event.SEEK, from: State.PAUSE, to: State.PAUSE},
        {name: Event.SEEKED, from: State.PAUSE, to: State.PAUSE},

        // ignore seeked during Playing because of not allowed keyboard seeking
        {name: Event.SEEKED, from: State.PLAYING, to: State.PLAYING},

        //#endregion
        //#endregion

        {name: Event.END, from: State.PLAY_SEEKING, to: State.END},
        {name: Event.END, from: State.PLAYING, to: State.END},
        {name: Event.END, from: State.PAUSE, to: State.END},
        {name: Event.SEEK, from: State.END, to: State.END},
        {name: Event.SEEKED, from: State.END, to: State.END},
        {name: Event.TIMECHANGED, from: State.END, to: State.END},
        {name: Event.END_BUFFERING, from: State.END, to: State.END},
        {name: Event.START_BUFFERING, from: State.END, to: State.END},
        {name: Event.END, from: State.END, to: State.END},

        {name: Event.PLAY, from: State.END, to: State.STARTUP},

        {name: Event.ERROR, from: this.getAllStatesBut([State.STARTUP]), to: State.ERROR},

        {name: Event.UNLOAD, from: this.getAllStatesBut([State.STARTUP, State.AD_STARTUP]), to: State.END},

        {name: Event.CUSTOM_DATA_CHANGE, from: [State.PLAYING, State.PAUSE], to: State.CUSTOMDATACHANGE},
        {name: Event.PLAYING, from: State.CUSTOMDATACHANGE, to: State.PLAYING},
        {name: Event.PAUSE, from: State.CUSTOMDATACHANGE, to: State.PAUSE},

        {name: Event.SUBTITLE_CHANGE, from: State.PLAYING, to: State.SUBTITLE_CHANGING},
        {name: Event.SUBTITLE_CHANGE, from: State.PAUSE, to: State.PAUSE},
        {name: Event.SUBTITLE_CHANGE, from: State.REBUFFERING, to: State.REBUFFERING},
        {name: Event.SUBTITLE_CHANGE, from: State.END_PLAY_SEEKING, to: State.END_PLAY_SEEKING},
        {name: Event.SUBTITLE_CHANGE, from: State.SUBTITLE_CHANGING, to: State.SUBTITLE_CHANGING},
        {name: Event.TIMECHANGED, from: State.SUBTITLE_CHANGING, to: State.PLAYING},

        {name: Event.AUDIOTRACK_CHANGED, from: State.PLAYING, to: State.AUDIOTRACK_CHANGING},
        {name: Event.AUDIOTRACK_CHANGED, from: State.PAUSE, to: State.PAUSE},
        {name: Event.AUDIOTRACK_CHANGED, from: State.REBUFFERING, to: State.REBUFFERING},
        {name: Event.AUDIOTRACK_CHANGED, from: State.END_PLAY_SEEKING, to: State.END_PLAY_SEEKING},
        {name: Event.AUDIOTRACK_CHANGED, from: State.AUDIOTRACK_CHANGING, to: State.AUDIOTRACK_CHANGING},
        {name: Event.TIMECHANGED, from: State.AUDIOTRACK_CHANGING, to: State.PLAYING},

        // #region Ads
        {name: Event.START_AD, from: State.STARTUP, to: State.AD_STARTUP},
        {name: Event.END_AD, from: State.AD_STARTUP, to: State.STARTUP},
        {name: Event.PLAY, from: State.AD_STARTUP, to: State.AD_STARTUP},
        {name: Event.PAUSE, from: State.AD_STARTUP, to: State.AD_STARTUP},

        {name: Event.START_AD, from: State.READY, to: State.AD_READY},
        /** After the pre-rolls, the main content will always start playing, no matter if autoplay: true/false */
        {name: Event.END_AD, from: State.AD_READY, to: State.STARTUP},
        {name: Event.PLAY, from: State.AD_READY, to: State.AD_READY},
        {name: Event.PAUSE, from: State.AD_READY, to: State.AD_READY},

        {name: Event.START_AD, from: State.PAUSE, to: State.AD_PAUSE},
        {name: Event.END_AD, from: State.AD_PAUSE, to: State.PAUSE},
        {name: Event.PLAY, from: State.AD_PAUSE, to: State.AD_PAUSE},
        {name: Event.PAUSE, from: State.AD_PAUSE, to: State.AD_PAUSE},

        {name: Event.START_AD, from: State.PLAYING, to: State.AD_PLAYING},
        {name: Event.END_AD, from: State.AD_PLAYING, to: State.PLAYING},
        {name: Event.PLAY, from: State.AD_PLAYING, to: State.AD_PLAYING},
        {name: Event.PAUSE, from: State.AD_PLAYING, to: State.AD_PLAYING},
        {name: Event.END, from: State.AD_PLAYING, to: State.END},

        // Ignore timechanged events during ads
        {name: Event.TIMECHANGED, from: State.AD_STARTUP, to: State.AD_STARTUP},
        {name: Event.TIMECHANGED, from: State.AD_READY, to: State.AD_READY},
        {name: Event.TIMECHANGED, from: State.AD_PAUSE, to: State.AD_PAUSE},
        {name: Event.TIMECHANGED, from: State.AD_PLAYING, to: State.AD_PLAYING},

        // Ignore End Ad events when no ad has started
        {name: Event.END_AD, from: State.PLAYING, to: State.PLAYING},
        {name: Event.END_AD, from: State.PAUSE, to: State.PAUSE},
        {name: Event.END_AD, from: State.READY, to: State.READY},
        {name: Event.END_AD, from: State.STARTUP, to: State.STARTUP},
        // #endregion

        {name: Event.MUTE, from: State.READY, to: State.MUTING_READY},
        {name: Event.UN_MUTE, from: State.READY, to: State.MUTING_READY},
        {name: 'FINISH_MUTING', from: State.MUTING_READY, to: State.READY},

        {name: Event.MUTE, from: State.PLAYING, to: State.MUTING_PLAY},
        {name: Event.UN_MUTE, from: State.PLAYING, to: State.MUTING_PLAY},
        {name: 'FINISH_MUTING', from: State.MUTING_PLAY, to: State.PLAYING},

        {name: Event.MUTE, from: State.PAUSE, to: State.MUTING_PAUSE},
        {name: Event.UN_MUTE, from: State.PAUSE, to: State.MUTING_PAUSE},
        {name: 'FINISH_MUTING', from: State.MUTING_PAUSE, to: State.PAUSE},

        {name: Event.START_CAST, from: [State.READY, State.PAUSE, State.PLAYING], to: State.CASTING},
        {name: Event.PAUSE, from: State.CASTING, to: State.CASTING},
        {name: Event.PLAY, from: State.CASTING, to: State.CASTING},
        {name: Event.PLAYING, from: State.CASTING, to: State.CASTING},
        {name: Event.TIMECHANGED, from: State.CASTING, to: State.CASTING},
        {name: Event.MUTE, from: State.CASTING, to: State.CASTING},
        {name: Event.UN_MUTE, from: State.CASTING, to: State.CASTING},
        {name: Event.SEEK, from: State.CASTING, to: State.CASTING},
        {name: Event.SEEKED, from: State.CASTING, to: State.CASTING},
        {name: Event.END_CAST, from: State.CASTING, to: State.READY},

        {name: Event.SEEK, from: State.READY, to: State.READY},
        {name: Event.SEEKED, from: State.READY, to: State.READY},
        {name: Event.SEEKED, from: State.STARTUP, to: State.STARTUP},

        {
          name: Event.MANUAL_SOURCE_CHANGE,
          from: this.getAllStatesBut([State.SETUP]),
          to: State.SOURCE_CHANGING,
        },
        {
          name: Event.MANUAL_SOURCE_CHANGE,
          from: State.SETUP,
          to: State.INITIAL_SOURCE_CHANGE,
        },
        {name: Event.SOURCE_UNLOADED, from: this.getAllStates(), to: State.SOURCE_CHANGING},

        // {name: Event.READY, from: State.SOURCE_CHANGING, to: State.READY},

        {name: Event.VIDEO_CHANGE, from: State.REBUFFERING, to: State.QUALITYCHANGE_REBUFFERING},
        {name: Event.AUDIO_CHANGE, from: State.REBUFFERING, to: State.QUALITYCHANGE_REBUFFERING},
        {
          name: Event.VIDEO_CHANGE,
          from: State.QUALITYCHANGE_REBUFFERING,
          to: State.QUALITYCHANGE_REBUFFERING,
        },
        {
          name: Event.AUDIO_CHANGE,
          from: State.QUALITYCHANGE_REBUFFERING,
          to: State.QUALITYCHANGE_REBUFFERING,
        },
        {
          name: 'FINISH_QUALITYCHANGE_REBUFFERING',
          from: State.QUALITYCHANGE_REBUFFERING,
          to: State.REBUFFERING,
        },
      ],
      callbacks: {
        onbeforeevent: (event, from, to, timestamp, eventObject): any => {
          if (from === State.REBUFFERING && to === State.QUALITYCHANGE_REBUFFERING) {
            return false;
          }
        },
        onafterevent: (event, from, to, timestamp, eventObject) => {
          if (event === Event.MANUAL_SOURCE_CHANGE && from === State.SETUP) {
            this.stateMachineCallbacks.initialSourceChange(eventObject);
          } else if (event === Event.MANUAL_SOURCE_CHANGE) {
            this.stateMachineCallbacks.manualSourceChange(eventObject);
          }

          if (to === State.QUALITYCHANGE_PAUSE) {
            this.stateMachine.FINISH_QUALITYCHANGE_PAUSE(timestamp);
          }
          if (to === State.QUALITYCHANGE) {
            this.stateMachine.FINISH_QUALITYCHANGE(timestamp);
          }
          if (to === State.QUALITYCHANGE_REBUFFERING) {
            this.stateMachine.FINISH_QUALITYCHANGE_REBUFFERING(timestamp);
          }
          if (to === State.MUTING_READY || to === State.MUTING_PLAY || to === State.MUTING_PAUSE) {
            this.stateMachine.FINISH_MUTING(timestamp);
          }
        },
        onenterstate: (
          event: string | undefined,
          from: string | undefined,
          to: string | undefined,
          timestamp: number,
          eventObject: any,
        ) => {
          if (from === 'none' && opts.starttime) {
            this.onEnterStateTimestamp = opts.starttime;
          } else {
            this.onEnterStateTimestamp = timestamp || new Date().getTime();
          }

          logger.log(
            `[ENTER ${timestamp}] ${padRight(to, 20)} EVENT: ${padRight(event, 20)} from: ${padRight(from, 14)}`,
          );

          if (
            eventObject &&
            to !== State.END_PLAY_SEEKING &&
            from !== State.PLAY_SEEKING &&
            from !== State.AD_STARTUP &&
            from !== State.AD_READY &&
            from !== State.AD_PLAYING &&
            from !== State.AD_PAUSE &&
            !(event === Event.SEEK && from === State.END_PLAY_SEEKING)
          ) {
            this.stateMachineCallbacks.setVideoTimeStartFromEvent(eventObject);
          }

          if (event === Event.START_CAST && to === State.CASTING) {
            this.stateMachineCallbacks.startCasting(timestamp, eventObject);
          }

          if (to === State.REBUFFERING) {
            this.startRebufferingHeartbeatInterval();
          }
        },
        onleavestate: (event, from, to, timestamp, eventObject) => {
          if (from === State.REBUFFERING) {
            this.resetRebufferingHelpers();
          }

          if (!timestamp) {
            return;
          }

          logger.log(
            `[LEAVE ${timestamp}] ${padRight(from, 20)} EVENT: ${padRight(event, 20)} to: ${padRight(to, 20)}`,
          );

          this.addStatesToLog(event, from, to, timestamp, eventObject);
          const stateDuration = timestamp - this.onEnterStateTimestamp;

          if (eventObject && to !== State.END_PLAY_SEEKING) {
            this.stateMachineCallbacks.setVideoTimeEndFromEvent(eventObject);
          }

          if (eventObject && event !== Event.END_AD) {
            this.lastTrackedVideoEndObject = eventObject;
          }

          if ((from === State.READY || from === State.AD_STARTUP) && to === State.STARTUP) {
            this.setVideoStartTimeout();
          } else if (from === State.STARTUP && (to === State.PLAYING || to === State.AD_STARTUP)) {
            this.clearVideoStartTimeout();
          }

          const fromStateName = String(from).toLowerCase();
          if (to === State.EXIT_BEFORE_VIDEOSTART) {
            this.clearVideoStartTimeout();
            const eventData = this.getVideoStartupFailedEventData(timestamp, event, eventObject);
            const shouldSendSample = event !== Event.ERROR;
            this.stateMachineCallbacks.videoStartFailed(eventData, shouldSendSample);
          } else if (from === State.END_PLAY_SEEKING) {
            const seekDuration = this.seekedTimestamp - this.seekTimestamp;

            // do not enter here if
            // 1. no seek has started because of SEEK
            // 2. no seek has ended because of SEEKED event
            // 3. leave of END_PLAY_SEEKING was triggered by another SEEK event (continuous seeking)
            if (this.seekStarted && this.seekEnded && event !== Event.SEEK) {
              this.seekStarted = this.seekEnded = false;
              this.stateMachineCallbacks.end_play_seeking(seekDuration, fromStateName);
            }
          } else if (event === Event.UNLOAD) {
            this.stateMachineCallbacks.unload(stateDuration, fromStateName);
          } else if (
            from === State.AD_PAUSE ||
            from === State.AD_READY ||
            from === State.AD_PLAYING ||
            from === State.AD_STARTUP
          ) {
            this.stateMachineCallbacks.setVideoTimeStartFromEvent(this.lastTrackedVideoEndObject);
            this.stateMachineCallbacks.setVideoTimeEndFromEvent(this.lastTrackedVideoEndObject);
            this.stateMachineCallbacks.ad(stateDuration, 'ad');
          } else if (event === Event.START_AD && from === State.STARTUP) {
            noOp();
          } else if (event === Event.SOURCE_LOADED && from === State.INITIAL_SOURCE_CHANGE) {
            noOp();
          } else if (from === State.SETUP) {
            // Setting Player Startup Time to fixed value of 1ms.
            // We can not get correct Player Startup Time from bitmovin player, because it does not have an "onInitialized" event
            this.stateMachineCallbacks.setup(1, State.SETUP.toLowerCase());
          } else if (event === Event.PAUSE && from === State.STARTUP && to === State.READY) {
            // fired PAUSE event in STARTUP state indicates we are moving back to READY,
            // so we have to clean setTimeout, and we should not call any stateMachineCallbacks
            this.clearVideoStartTimeout();
          } else if (from !== State.ERROR) {
            const callbackFunction = this.stateMachineCallbacks[fromStateName];
            if (typeof callbackFunction === 'function') {
              try {
                callbackFunction(stateDuration, fromStateName, eventObject);
              } catch (e) {
                logger.error('Exception occured in State Machine callback ' + fromStateName, eventObject, e);
              }
            } else {
              logger.error('Could not find callback function for ' + fromStateName);
            }
          }

          if (
            eventObject &&
            to !== State.END_PLAY_SEEKING &&
            from !== State.PLAY_SEEKING &&
            to !== State.PLAY_SEEKING &&
            from !== State.AD_STARTUP &&
            from !== State.AD_READY &&
            from !== State.AD_PLAYING &&
            from !== State.AD_PAUSE
          ) {
            this.stateMachineCallbacks.setVideoTimeStartFromEvent(eventObject);
          }

          if (event === Event.VIDEO_CHANGE) {
            this.stateMachineCallbacks.videoChange(eventObject);
          } else if (event === Event.AUDIO_CHANGE) {
            this.stateMachineCallbacks.audioChange(eventObject);
          } else if (event === Event.MUTE) {
            this.stateMachineCallbacks.mute();
          } else if (event === Event.UN_MUTE) {
            this.stateMachineCallbacks.unMute();
          }
        },
        onseek: (event, from, to, timestamp) => {
          if (!this.seekStarted) {
            this.seekTimestamp = timestamp;
            this.seekStarted = true;
          }
        },
        onseeked: (event, from, to, timestamp) => {
          this.seekedTimestamp = timestamp;
          this.seekEnded = true;
        },
        ontimechanged: (event, from, to, timestamp, eventObject) => {
          if (this.stateMachine.current !== 'PLAYING') {
            return;
          }

          const stateDuration = timestamp - this.onEnterStateTimestamp;

          if (stateDuration > 59700) {
            this.stateMachineCallbacks.setVideoTimeEndFromEvent(eventObject);

            this.stateMachineCallbacks.heartbeat(stateDuration, String(from).toLowerCase(), {played: stateDuration});
            this.onEnterStateTimestamp = timestamp;

            this.stateMachineCallbacks.setVideoTimeStartFromEvent(eventObject);
          }
        },
        onplayerError: (event, from, to, timestamp, eventObject) => {
          this.stateMachineCallbacks.error(eventObject);
        },
      },
    });
  }

  callEvent<StatemachineEvent extends keyof EventMap, EventData extends EventMap[StatemachineEvent]>(
    eventType: StatemachineEvent,
    eventObject: NoExtraProperties<EventMap[StatemachineEvent], EventData>,
    timestamp: number,
  ): void {
    const exec = this.stateMachine[eventType];

    if (exec) {
      try {
        exec.call(this.stateMachine, timestamp, eventObject);
      } catch (e) {
        logger.error('Exception occured in State Machine callback ' + eventType, exec, eventObject, e);
      }
    } else {
      logger.log('Ignored Event: ' + eventType);
    }
  }

  addStatesToLog(
    event: string | undefined,
    from: string | undefined,
    to: string | undefined,
    timestamp: number,
    eventObject: any,
  ) {
    if (this.enabledDebugging) {
      this.debuggingStates.push(new EventDebugging(event, from, to, timestamp, eventObject));
    }
  }

  getStates() {
    return this.debuggingStates;
  }

  setEnabledDebugging(enabled: boolean) {
    this.enabledDebugging = enabled;
  }
}
