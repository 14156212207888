import * as StateMachine from 'javascript-state-machine';

import {Event, EventMap} from '../enums/Event';
import {AnalyticsConfig} from '../types/AnalyticsConfig';
import {AnalyticsStateMachineOptions} from '../types/AnalyticsStateMachineOptions';
import {NoExtraProperties} from '../types/NoExtraProperties';
import {StateMachineCallbacks} from '../types/StateMachineCallbacks';
import {logger, padRight} from '../utils/Logger';

import {AnalyticsStateMachine} from './AnalyticsStateMachine';

enum State {
  AD = 'AD',
  SETUP = 'SETUP',
  STARTUP = 'STARTUP',
  READY = 'READY',
  PLAYING = 'PLAYING',
  REBUFFERING = 'REBUFFERING',
  PAUSE = 'PAUSE',
  QUALITYCHANGE = 'QUALITYCHANGE',
  PAUSED_SEEKING = 'PAUSED_SEEKING',
  PLAY_SEEKING = 'PLAY_SEEKING',
  END_PLAY_SEEKING = 'END_PLAY_SEEKING',
  QUALITYCHANGE_PAUSE = 'QUALITYCHANGE_PAUSE',
  QUALITYCHANGE_REBUFFERING = 'QUALITYCHANGE_REBUFFERING',
  SOURCE_CHANGING = 'SOURCE_CHANGING',
  END = 'END',
  ERROR = 'ERROR',
  MUTING_READY = 'MUTING_READY',
  MUTING_PLAY = 'MUTING_PLAY',
  MUTING_PAUSE = 'MUTING_PAUSE',
  CASTING = 'CASTING',
  AUDIOTRACK_CHANGING = 'AUDIOTRACK_CHANGING',
  AUDIOTRACK_CHANGED_PLAYING = 'AUDIOTRACK_CHANGED_PLAYING',
  SUBTITLE_CHANGING = 'SUBTITLE_CHANGING',
  SUBTITLE_CHANGED_PLAYING = 'SUBTITLE_CHANGED_PLAYING',
  EXIT_BEFORE_VIDEOSTART = 'EXIT_BEFORE_VIDEOSTART',
  CUSTOMDATACHANGE = 'CUSTOMDATACHANGE',
}

export class VideojsAnalyticsStateMachine extends AnalyticsStateMachine {
  private seekTimestamp: number;
  private seekedTimestamp: number;

  constructor(stateMachineCallbacks: StateMachineCallbacks, opts: AnalyticsStateMachineOptions) {
    super(stateMachineCallbacks, opts);

    this.seekTimestamp = 0;
    this.seekedTimestamp = 0;
  }

  getAllStates() {
    return [
      ...Object.keys(State).map((key) => State[key]),
      'FINISH_PLAY_SEEKING',
      'PLAY_SEEK',
      'FINISH_QUALITYCHANGE_PAUSE',
      'FINISH_QUALITYCHANGE',
      'FINISH_QUALITYCHANGE_REBUFFERING',
    ];
  }

  getAllStatesBut(state: string) {
    return this.getAllStates().filter((i) => i !== state);
  }

  createStateMachine(opts: AnalyticsStateMachineOptions) {
    return StateMachine.create({
      initial: State.SETUP,
      error: (eventName, from, to, args, errorCode, errorMessage) => {
        logger.error('Error in statemachine: ' + errorMessage);
      },
      events: [
        {name: Event.SOURCE_LOADED, from: [State.SETUP, State.ERROR, State.SOURCE_CHANGING], to: State.READY},
        {name: Event.TIMECHANGED, from: State.SETUP, to: State.SETUP},
        {name: Event.SOURCE_LOADED, from: State.READY, to: State.READY},
        {name: Event.READY, from: [State.SETUP, State.ERROR], to: State.READY},
        {name: Event.PLAY, from: State.READY, to: State.STARTUP},

        {name: Event.ERROR, from: State.STARTUP, to: State.EXIT_BEFORE_VIDEOSTART},
        {name: Event.UNLOAD, from: State.STARTUP, to: State.EXIT_BEFORE_VIDEOSTART},
        {name: Event.VIDEOSTART_TIMEOUT, from: State.STARTUP, to: State.EXIT_BEFORE_VIDEOSTART},

        {name: Event.START_BUFFERING, from: State.STARTUP, to: State.STARTUP},
        {name: Event.END_BUFFERING, from: State.STARTUP, to: State.STARTUP},
        {name: Event.VIDEO_CHANGE, from: State.STARTUP, to: State.STARTUP},
        {name: Event.AUDIO_CHANGE, from: State.STARTUP, to: State.STARTUP},
        {name: Event.TIMECHANGED, from: State.STARTUP, to: State.PLAYING},

        {name: Event.TIMECHANGED, from: State.PLAYING, to: State.PLAYING},
        {name: Event.END_BUFFERING, from: State.PLAYING, to: State.PLAYING},
        {name: Event.START_BUFFERING, from: State.PLAYING, to: State.REBUFFERING},
        {name: Event.START_BUFFERING, from: State.REBUFFERING, to: State.REBUFFERING},
        {name: Event.TIMECHANGED, from: State.REBUFFERING, to: State.PLAYING},

        // Ignoring since it's pushed in a live stream
        {name: Event.SEEK, from: State.STARTUP, to: State.STARTUP},
        {name: Event.SEEK, from: State.PLAYING, to: State.PLAY_SEEKING},
        {name: Event.TIMECHANGED, from: State.PLAY_SEEKING, to: State.PLAY_SEEKING},
        {name: Event.TIMECHANGED, from: State.PAUSED_SEEKING, to: State.PAUSED_SEEKING},

        {name: Event.PAUSE, from: State.PLAYING, to: State.PAUSE},
        {name: Event.PAUSE, from: State.REBUFFERING, to: State.PAUSE},
        {name: Event.TIMECHANGED, from: State.PAUSE, to: State.PAUSE},
        {name: Event.PLAY, from: State.PAUSE, to: State.PLAYING},

        {name: Event.VIDEO_CHANGE, from: State.PLAYING, to: State.QUALITYCHANGE},
        {name: Event.AUDIO_CHANGE, from: State.PLAYING, to: State.QUALITYCHANGE},
        {name: Event.VIDEO_CHANGE, from: State.QUALITYCHANGE, to: State.QUALITYCHANGE},
        {name: Event.AUDIO_CHANGE, from: State.QUALITYCHANGE, to: State.QUALITYCHANGE},
        {name: 'FINISH_QUALITYCHANGE', from: State.QUALITYCHANGE, to: State.PLAYING},

        {name: Event.VIDEO_CHANGE, from: State.PAUSE, to: State.QUALITYCHANGE_PAUSE},
        {name: Event.AUDIO_CHANGE, from: State.PAUSE, to: State.QUALITYCHANGE_PAUSE},
        {
          name: Event.VIDEO_CHANGE,
          from: State.QUALITYCHANGE_PAUSE,
          to: State.QUALITYCHANGE_PAUSE,
        },
        {
          name: Event.AUDIO_CHANGE,
          from: State.QUALITYCHANGE_PAUSE,
          to: State.QUALITYCHANGE_PAUSE,
        },
        {name: 'FINISH_QUALITYCHANGE_PAUSE', from: State.QUALITYCHANGE_PAUSE, to: State.PAUSE},

        {name: Event.SEEK, from: State.PAUSE, to: State.PAUSED_SEEKING},
        {name: Event.SEEK, from: State.PAUSED_SEEKING, to: State.PAUSED_SEEKING},
        {name: Event.AUDIO_CHANGE, from: State.PAUSED_SEEKING, to: State.PAUSED_SEEKING},
        {name: Event.VIDEO_CHANGE, from: State.PAUSED_SEEKING, to: State.PAUSED_SEEKING},
        {name: Event.START_BUFFERING, from: State.PAUSED_SEEKING, to: State.PAUSED_SEEKING},
        {name: Event.END_BUFFERING, from: State.PAUSED_SEEKING, to: State.PAUSED_SEEKING},
        {name: Event.SEEKED, from: State.PAUSED_SEEKING, to: State.PAUSE},
        {name: Event.PLAY, from: State.PAUSED_SEEKING, to: State.PLAYING},
        {name: Event.PAUSE, from: State.PAUSED_SEEKING, to: State.PAUSE},

        {name: 'PLAY_SEEK', from: State.PAUSE, to: State.PLAY_SEEKING},
        {name: 'PLAY_SEEK', from: State.PAUSED_SEEKING, to: State.PLAY_SEEKING},
        {name: 'PLAY_SEEK', from: State.PLAY_SEEKING, to: State.PLAY_SEEKING},
        {name: Event.SEEK, from: State.PLAY_SEEKING, to: State.PLAY_SEEKING},
        {name: Event.AUDIO_CHANGE, from: State.PLAY_SEEKING, to: State.PLAY_SEEKING},
        {name: Event.VIDEO_CHANGE, from: State.PLAY_SEEKING, to: State.PLAY_SEEKING},
        {name: Event.START_BUFFERING, from: State.PLAY_SEEKING, to: State.PLAY_SEEKING},
        {name: Event.END_BUFFERING, from: State.PLAY_SEEKING, to: State.PLAY_SEEKING},
        {name: Event.SEEKED, from: State.PLAY_SEEKING, to: State.PLAY_SEEKING},

        // We are ending the seek
        {name: Event.SEEKED, from: State.PLAY_SEEKING, to: State.END_PLAY_SEEKING},

        {name: Event.START_BUFFERING, from: State.END_PLAY_SEEKING, to: State.END_PLAY_SEEKING},
        {name: Event.END_BUFFERING, from: State.END_PLAY_SEEKING, to: State.END_PLAY_SEEKING},
        {name: Event.SEEKED, from: State.END_PLAY_SEEKING, to: State.END_PLAY_SEEKING},
        {name: Event.TIMECHANGED, from: State.END_PLAY_SEEKING, to: State.PLAYING},

        {name: Event.END, from: State.PLAY_SEEKING, to: State.END},
        {name: Event.END, from: State.PAUSED_SEEKING, to: State.END},
        {name: Event.END, from: State.PLAYING, to: State.END},
        {name: Event.END, from: State.PAUSE, to: State.END},
        {name: Event.SEEK, from: State.END, to: State.END},
        {name: Event.SEEKED, from: State.END, to: State.END},
        {name: Event.TIMECHANGED, from: State.END, to: State.END},
        {name: Event.END_BUFFERING, from: State.END, to: State.END},
        {name: Event.START_BUFFERING, from: State.END, to: State.END},
        {name: Event.END, from: State.END, to: State.END},

        // Ignored - Livestreams do a Seek during startup and SEEKED once playback started
        {name: Event.SEEKED, from: State.PLAYING, to: State.PLAYING},

        {name: Event.PLAY, from: State.END, to: State.PLAYING},

        {name: Event.ERROR, from: this.getAllStatesBut(State.STARTUP), to: State.ERROR},
        {name: Event.PAUSE, from: State.ERROR, to: State.ERROR},

        {name: Event.SEEK, from: State.END_PLAY_SEEKING, to: State.PLAY_SEEKING},
        {name: 'FINISH_PLAY_SEEKING', from: State.END_PLAY_SEEKING, to: State.PLAYING},

        {name: Event.UNLOAD, from: this.getAllStatesBut(State.STARTUP), to: State.END},

        {name: Event.START_AD, from: State.PLAYING, to: State.AD},
        {name: Event.END_AD, from: State.AD, to: State.PLAYING},

        {name: Event.MUTE, from: State.READY, to: State.MUTING_READY},
        {name: Event.UN_MUTE, from: State.READY, to: State.MUTING_READY},
        {name: 'FINISH_MUTING', from: State.MUTING_READY, to: State.READY},

        {name: Event.MUTE, from: State.PLAYING, to: State.MUTING_PLAY},
        {name: Event.UN_MUTE, from: State.PLAYING, to: State.MUTING_PLAY},
        {name: 'FINISH_MUTING', from: State.MUTING_PLAY, to: State.PLAYING},

        {name: Event.MUTE, from: State.PAUSE, to: State.MUTING_PAUSE},
        {name: Event.UN_MUTE, from: State.PAUSE, to: State.MUTING_PAUSE},
        {name: 'FINISH_MUTING', from: State.MUTING_PAUSE, to: State.PAUSE},

        {name: Event.START_CAST, from: [State.READY, State.PAUSE], to: State.CASTING},
        {name: Event.PAUSE, from: State.CASTING, to: State.CASTING},
        {name: Event.PLAY, from: State.CASTING, to: State.CASTING},
        {name: Event.TIMECHANGED, from: State.CASTING, to: State.CASTING},
        {name: Event.MUTE, from: State.CASTING, to: State.CASTING},
        {name: Event.SEEK, from: State.CASTING, to: State.CASTING},
        {name: Event.SEEKED, from: State.CASTING, to: State.CASTING},
        {name: Event.END_CAST, from: State.CASTING, to: State.READY},

        {name: Event.SEEK, from: State.READY, to: State.READY},
        {name: Event.SEEKED, from: State.READY, to: State.READY},
        {name: Event.SEEKED, from: State.STARTUP, to: State.STARTUP},

        {name: Event.AUDIOTRACK_CHANGED, from: State.PLAYING, to: State.AUDIOTRACK_CHANGED_PLAYING},
        {name: 'FINISH_AUDIOTRACK_CHANGING', from: State.AUDIOTRACK_CHANGED_PLAYING, to: State.PLAYING},
        // States which should ignore audioTrack changes
        {name: Event.AUDIOTRACK_CHANGED, from: State.PAUSE, to: State.PAUSE},
        {name: Event.AUDIOTRACK_CHANGED, from: State.READY, to: State.READY},
        {name: Event.AUDIOTRACK_CHANGED, from: State.REBUFFERING, to: State.REBUFFERING},
        {name: Event.AUDIOTRACK_CHANGED, from: State.END_PLAY_SEEKING, to: State.END_PLAY_SEEKING},
        {name: Event.AUDIOTRACK_CHANGED, from: State.AUDIOTRACK_CHANGING, to: State.AUDIOTRACK_CHANGING},

        {name: Event.SUBTITLE_CHANGE, from: State.PLAYING, to: State.SUBTITLE_CHANGED_PLAYING},
        {name: 'FINISH_SUBTITLE_CHANGING', from: State.SUBTITLE_CHANGED_PLAYING, to: State.PLAYING},
        // States which should ignore subtitle changes
        {name: Event.SUBTITLE_CHANGE, from: State.PAUSE, to: State.PAUSE},
        {name: Event.SUBTITLE_CHANGE, from: State.READY, to: State.READY},
        {name: Event.SUBTITLE_CHANGE, from: State.REBUFFERING, to: State.REBUFFERING},
        {name: Event.SUBTITLE_CHANGE, from: State.END_PLAY_SEEKING, to: State.END_PLAY_SEEKING},
        {name: Event.SUBTITLE_CHANGE, from: State.SUBTITLE_CHANGING, to: State.SUBTITLE_CHANGING},

        {
          name: Event.MANUAL_SOURCE_CHANGE,
          from: this.getAllStatesBut(State.READY),
          to: State.SOURCE_CHANGING,
        },
        {
          name: Event.MANUAL_SOURCE_CHANGE,
          from: State.READY,
          to: State.READY,
        },
        {name: Event.MANUAL_SOURCE_CHANGE, from: State.SETUP, to: State.SETUP},
        {name: Event.TIMECHANGED, from: State.SOURCE_CHANGING, to: State.SOURCE_CHANGING},

        {name: Event.VIDEO_CHANGE, from: State.REBUFFERING, to: State.QUALITYCHANGE_REBUFFERING},
        {name: Event.AUDIO_CHANGE, from: State.REBUFFERING, to: State.QUALITYCHANGE_REBUFFERING},
        {
          name: Event.VIDEO_CHANGE,
          from: State.QUALITYCHANGE_REBUFFERING,
          to: State.QUALITYCHANGE_REBUFFERING,
        },
        {
          name: Event.AUDIO_CHANGE,
          from: State.QUALITYCHANGE_REBUFFERING,
          to: State.QUALITYCHANGE_REBUFFERING,
        },
        {
          name: 'FINISH_QUALITYCHANGE_REBUFFERING',
          from: State.QUALITYCHANGE_REBUFFERING,
          to: State.REBUFFERING,
        },

        {name: Event.CUSTOM_DATA_CHANGE, from: [State.PLAYING, State.PAUSE], to: State.CUSTOMDATACHANGE},
        {name: Event.PLAYING, from: State.CUSTOMDATACHANGE, to: State.PLAYING},
        {name: Event.PAUSE, from: State.CUSTOMDATACHANGE, to: State.PAUSE},
      ],
      callbacks: {
        onafterevent: (event, from, to, timestamp) => {
          if (to === State.QUALITYCHANGE) {
            this.stateMachine.FINISH_QUALITYCHANGE(timestamp);
          }
          if (to === State.MUTING_READY || to === State.MUTING_PLAY || to === State.MUTING_PAUSE) {
            this.stateMachine.FINISH_MUTING(timestamp);
          }
          if (to === State.AUDIOTRACK_CHANGED_PLAYING) {
            this.stateMachine.FINISH_AUDIOTRACK_CHANGING(timestamp);
          }
          if (to === State.SUBTITLE_CHANGED_PLAYING) {
            this.stateMachine.FINISH_SUBTITLE_CHANGING(timestamp);
          }
        },
        onenterstate: (event, from, to, timestamp, eventObject) => {
          if (from === 'none' && opts.starttime) {
            this.onEnterStateTimestamp = opts.starttime;
          } else {
            this.onEnterStateTimestamp = timestamp || new Date().getTime();
          }

          logger.log('[ENTER] ' + padRight(to, 20) + 'EVENT: ' + padRight(event, 20) + ' from ' + padRight(from, 14));

          if (
            eventObject &&
            to !== State.PAUSED_SEEKING &&
            to !== State.PLAY_SEEKING &&
            to !== State.END_PLAY_SEEKING
          ) {
            this.stateMachineCallbacks.setVideoTimeStartFromEvent(eventObject);
          }

          if (event === Event.SEEK) {
            this.stateMachineCallbacks.setVideoTimeStartFromEvent(eventObject);
          }

          if (event === Event.SEEKED) {
            this.stateMachineCallbacks.setVideoTimeEndFromEvent(eventObject);
          }

          if (
            event === 'PLAY_SEEK' &&
            to === State.PLAY_SEEKING &&
            to !== State.PLAY_SEEKING &&
            to !== State.END_PLAY_SEEKING
          ) {
            this.seekTimestamp = this.onEnterStateTimestamp;
          }

          if (event === Event.START_CAST && to === State.CASTING) {
            this.stateMachineCallbacks.startCasting(timestamp, eventObject);
          }

          if (to === State.REBUFFERING) {
            this.startRebufferingHeartbeatInterval();
          }
        },
        onleavestate: (event, from, to, timestamp, eventObject): any => {
          if (from === State.REBUFFERING) {
            this.resetRebufferingHelpers();
          }

          if (!timestamp) {
            return;
          }
          logger.log(`[LEAVE] ${padRight(from, 20)} EVENT: ${padRight(event, 20)} to ${padRight(to, 20)}`);

          const stateDuration = timestamp - this.onEnterStateTimestamp;

          if (eventObject && to !== State.PAUSED_SEEKING && to !== State.END_PLAY_SEEKING) {
            this.stateMachineCallbacks.setVideoTimeEndFromEvent(eventObject);
          }

          if (event === 'PLAY_SEEK' && from === State.PAUSE) {
            return true;
          }

          if (from === State.READY && to === State.STARTUP) {
            this.setVideoStartTimeout();
          } else if (from === State.STARTUP && (to === State.PLAYING || to === State.EXIT_BEFORE_VIDEOSTART)) {
            this.clearVideoStartTimeout();
          }

          const fnName = String(from).toLowerCase();
          if (to === State.EXIT_BEFORE_VIDEOSTART) {
            this.clearVideoStartTimeout();
            const eventData = this.getVideoStartupFailedEventData(timestamp, event, eventObject);
            const shouldSendSample = event !== Event.ERROR;
            this.stateMachineCallbacks.videoStartFailed(eventData, shouldSendSample);
          } else if (from === State.PAUSED_SEEKING) {
            const seekDuration = timestamp - this.seekTimestamp;
            this.stateMachineCallbacks.paused_seeking(seekDuration, fnName);
          } else if (from === State.END_PLAY_SEEKING) {
            const seekDuration = this.seekedTimestamp - this.seekTimestamp;
            this.stateMachineCallbacks.end_play_seeking(seekDuration, fnName);
          } else if (event === Event.UNLOAD) {
            this.stateMachineCallbacks.unload(stateDuration, fnName);
          } else if (from === State.PAUSE && to !== State.PAUSED_SEEKING) {
            this.stateMachineCallbacks.setVideoTimeStartFromEvent(event);
            this.stateMachineCallbacks.pause(stateDuration, fnName);
          } else {
            const callbackFunction = this.stateMachineCallbacks[fnName];
            if (typeof callbackFunction === 'function') {
              callbackFunction(stateDuration, fnName, eventObject);
            } else {
              const excludedStates = [
                State.MUTING_PAUSE,
                State.MUTING_PLAY,
                State.AUDIOTRACK_CHANGED_PLAYING,
                State.SUBTITLE_CHANGED_PLAYING,
              ];
              if (excludedStates.find((e) => from === e) == null) {
                logger.error('Could not find callback function for ' + fnName);
              }
            }
          }

          if (
            eventObject &&
            to !== State.PAUSED_SEEKING &&
            to !== State.PLAY_SEEKING &&
            to !== State.END_PLAY_SEEKING
          ) {
            this.stateMachineCallbacks.setVideoTimeStartFromEvent(eventObject);
          }

          if (event === Event.VIDEO_CHANGE) {
            this.stateMachineCallbacks.videoChange(eventObject);
          } else if (event === Event.AUDIO_CHANGE) {
            this.stateMachineCallbacks.audioChange(eventObject);
          } else if (event === Event.MUTE) {
            this.stateMachineCallbacks.mute();
          } else if (event === Event.UN_MUTE) {
            this.stateMachineCallbacks.unMute();
          } else if (event === Event.MANUAL_SOURCE_CHANGE) {
            this.stateMachineCallbacks.manualSourceChange(eventObject);
          }
        },
        onseek: (event, from, to, timestamp) => {
          this.seekTimestamp = timestamp;
        },
        onseeked: (event, from, to, timestamp) => {
          this.seekedTimestamp = timestamp;
        },
        ontimechanged: (event, from, to, timestamp, eventObject) => {
          const stateDuration = timestamp - this.onEnterStateTimestamp;

          if (stateDuration > 59700) {
            this.stateMachineCallbacks.setVideoTimeEndFromEvent(eventObject);

            this.stateMachineCallbacks.heartbeat(stateDuration, String(from).toLowerCase(), {played: stateDuration});
            this.onEnterStateTimestamp = timestamp;

            this.stateMachineCallbacks.setVideoTimeStartFromEvent(eventObject);
          }
        },
        onplayerError: (event, from, to, timestamp, eventObject) => {
          this.stateMachineCallbacks.error(eventObject);
        },
      },
    });
  }

  callEvent<StatemachineEvent extends keyof EventMap, EventData extends EventMap[StatemachineEvent]>(
    eventType: StatemachineEvent,
    eventObject: NoExtraProperties<EventMap[StatemachineEvent], EventData>,
    timestamp: number,
  ): void {
    const exec = this.stateMachine[eventType];

    if (exec) {
      exec.call(this.stateMachine, timestamp, eventObject);
    } else {
      logger.log('Ignored Event: ' + eventType);
    }
  }

  sourceChange = (config: AnalyticsConfig, timestamp: number, currentTime?: number) => {
    this.callEvent(Event.MANUAL_SOURCE_CHANGE, {config, currentTime}, timestamp);
  };
}
