import type {AudioTrackSwitchedData, SubtitleTrackSwitchData} from 'hls.js';

import {Analytics} from '../../core/Analytics';
import {DownloadSpeedMeter} from '../../core/DownloadSpeedMeter';
import {Event} from '../../enums/Event';
import {MIMETypes} from '../../enums/MIMETypes';
import {Player} from '../../enums/Player';
import {Feature} from '../../features/Feature';
import {FeatureConfig} from '../../features/FeatureConfig';
import {ErrorDetailBackend} from '../../features/errordetails/ErrorDetailBackend';
import {ErrorDetailTracking} from '../../features/errordetails/ErrorDetailTracking';
import {AnalyticsStateMachineOptions} from '../../types/AnalyticsStateMachineOptions';
import {DownloadSpeedInfo} from '../../types/DownloadSpeedInfo';
import {FeatureConfigContainer} from '../../types/FeatureConfigContainer';
import {PlaybackInfo} from '../../types/PlaybackInfo';
import {QualityLevelInfo} from '../../types/QualityLevelInfo';
import {SubtitleInfo} from '../../types/SubtitleInfo';

import {HTML5InternalAdapter} from './HTML5InternalAdapter';
import {HlsSpeedMeterAdapter} from './HlsSpeedMeterAdapter';
import {InternalAdapterAPI} from './InternalAdapterAPI';

export class HlsInternalAdapter extends HTML5InternalAdapter implements InternalAdapterAPI {
  override get downloadSpeedInfo(): DownloadSpeedInfo {
    return this.speedMeter.getInfo();
  }

  private speedMeter: DownloadSpeedMeter;

  constructor(
    private hls: any,
    opts?: AnalyticsStateMachineOptions,
  ) {
    super(undefined, opts);
    this.resetMedia();
    this.speedMeter = new HlsSpeedMeterAdapter(hls, new DownloadSpeedMeter()).getDownloadSpeedMeter();
  }

  override initialize(analytics: Analytics): Array<Feature<FeatureConfigContainer, FeatureConfig>> {
    super.initialize(analytics);
    this.registerHlsEvents();
    const errorDetailTracking = new ErrorDetailTracking(
      analytics.errorDetailTrackingSettingsProvider,
      new ErrorDetailBackend(analytics.errorDetailTrackingSettingsProvider.collectorConfig),
      [analytics.errorDetailSubscribable],
      undefined,
    );
    return [errorDetailTracking];
  }

  override clearValues(): void {
    this.speedMeter.reset();
  }

  override getPlayerName = () => Player.HLSJS;

  getPlayerVersion = () => (this.hls as any).constructor.version;

  getCurrentQualityLevelInfo(): QualityLevelInfo | null {
    if (!this.hls.levels) {
      return null;
    }
    const currentLevelObj = this.hls.levels[this.hls.currentLevel];
    if (!currentLevelObj) {
      return null;
    }

    const bitrate = currentLevelObj.bitrate;
    const width = currentLevelObj.width;
    const height = currentLevelObj.height;

    return {
      bitrate,
      width,
      height,
    };
  }

  override isLive = () => {
    const hls = this.hls;
    if (!hls.levels || !hls.currentLevel) {
      return false;
    }
    if (hls.currentLevel < 0) {
      return false;
    }
    const currentLevelObj = hls.levels[hls.currentLevel];
    if (!currentLevelObj || !currentLevelObj.details) {
      return false;
    }
    return currentLevelObj.details.live;
  };

  override getMIMEType() {
    return MIMETypes.HLS;
  }

  override getStreamURL() {
    return (this.hls as any).url;
  }

  registerHlsEvents() {
    this.hls.on('hlsMediaAttaching', this.onMediaAttaching.bind(this));
    this.hls.on('hlsMediaDetaching', this.onMediaDetaching.bind(this));
    this.hls.on('hlsManifestLoading', this.onManifestLoading.bind(this));
    this.hls.on('hlsAudioTrackSwitched', this.onAudioTrackSwitched.bind(this));
    this.hls.on('hlsSubtitleTrackSwitch', this.onSubtitleLanguageSwitched.bind(this));
    this.hls.on('hlsDestroying', this.onDestroying.bind(this));
    this.hls.on('hlsError', this.onHlsError.bind(this));

    // media is already attached, event has been triggered before
    // or we are in the event handler of this event itself.
    // we can not know how the stacktrace to this constructor will look like.
    // therefore we will guard from this case in
    // the onMediaAttaching method (avoid running it twice)
    if (this.hls.media) {
      this.onMediaAttaching();
      this.onManifestLoading();
    }
  }

  onMediaAttaching() {
    // in case we are called again (when we are triggering this ourselves
    // but from the event handler of MEDIA_ATTACHING) we should not run again.
    if (this.mediaElement) {
      return;
    }

    this.mediaElement = this.hls.media as HTMLVideoElement;

    this.registerMediaElement();
    this.onMaybeReady();
  }

  onMediaDetaching() {
    this.unregisterMediaElement();
  }

  onManifestLoading() {
    this.onMaybeReady();
  }

  override getCurrentPlaybackInfo(): PlaybackInfo {
    const selectedSubtitle = this.getSelectedSubtitleLanguage();

    const info: PlaybackInfo = {
      ...super.getCurrentPlaybackInfo(),
      audioLanguage: this.getSelectedAudioLanguage(),
      subtitleEnabled: selectedSubtitle != null ? selectedSubtitle.enabled : undefined,
      subtitleLanguage: selectedSubtitle != null ? selectedSubtitle.language : undefined,
    };

    return info;
  }

  /**
   * errorData: { type : error type, details : error details, fatal : if true, hls.js cannot/will not try to recover, if false, hls.js will try to recover,other error specific data }
   * https://hls-js.netlify.com/api-docs/file/src/events.js.html#lineNumber98
   */
  onHlsError(errorName, errorData) {
    const isFatal = errorData ? errorData.fatal : true;
    if (isFatal) {
      const mediaElement = this.mediaElement;
      let currentTime;
      if (mediaElement != null) {
        currentTime = mediaElement.currentTime;
      }

      const errorMessage = errorData != null ? `${errorData.type}: ${errorData.details}` : undefined;
      this.eventCallback(Event.ERROR, {
        currentTime,
        code: this.getErrorCodeFromHlsErrorType(errorData.type),
        message: errorMessage,
        data: {},
      });
    }
  }

  onAudioTrackSwitched(event: string, data: AudioTrackSwitchedData) {
    const mediaElement = this.mediaElement;
    let currentTime;
    if (mediaElement != null) {
      currentTime = mediaElement.currentTime;
    }

    this.eventCallback(Event.AUDIOTRACK_CHANGED, {
      currentTime,
    });
  }

  onSubtitleLanguageSwitched(event: string, data: SubtitleTrackSwitchData) {
    const mediaElement = this.mediaElement;
    let currentTime;
    if (mediaElement != null) {
      currentTime = mediaElement.currentTime;
    }

    this.eventCallback(Event.SUBTITLE_CHANGE, {
      currentTime,
    });
  }

  private onDestroying = () => {
    this.eventCallback(Event.SOURCE_UNLOADED, {});
    this.release();
  };

  /**
   * returns mapped error code for Hlsjs ErrorTypes
   * @param type one of the ErrorTypes according to https://github.com/video-dev/hls.js/blob/v1.5.1/src/errors.ts#L1
   */
  private getErrorCodeFromHlsErrorType(type: string): number {
    switch (type) {
      case 'networkError':
        return 2;
      case 'mediaError':
        return 3;
      case 'keySystemError':
        return 4;
      case 'muxError':
        return 5;
      // default equals `otherError`
      default:
        return 1;
    }
  }

  private getSelectedAudioLanguage(): string | undefined {
    if (this.hls.audioTrack == null || this.hls.audioTrack < 0) {
      return undefined;
    }
    return (this.hls.audioTracks[this.hls.audioTrack] as any).lang;
  }

  private getSelectedSubtitleLanguage(): SubtitleInfo | undefined {
    // Check if function is supported (in latest hls version), otherwise use fallback option
    if (this.hls.subtitleDisplay != null) {
      const isSubtitleDisplayed = this.hls.subtitleTrack >= 0 && this.hls.subtitleDisplay === true;
      return {
        enabled: isSubtitleDisplayed,
        language: isSubtitleDisplayed ? this.hls.subtitleTracks[this.hls.subtitleTrack].lang : undefined,
      };
    } else {
      const {subtitleTrackController} = this.hls as any;
      if (subtitleTrackController != null && subtitleTrackController.media != null) {
        return this.getSelectedSubtitleFromMediaElement(subtitleTrackController.media);
      }
    }
    return undefined;
  }
}
